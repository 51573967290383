// src/theme/hooks/useDesignToken.js

import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleTheme, selectTheme } from '../../store/slice/themeSlice';

const darkTheme = {
  colorPrimary: '#DDD',
  colorTextBase: '#DDD',
  colorBgElevated: '#555',
  colorBorder: '#555',
};

const lightTheme = {
  colorPrimary: '#AAA',
  colorTextBase: '#333',
  colorBgElevated: '#FFF',
  colorBorder: '#DDD',
};

export function useDesignToken() {
  const theme = useSelector(selectTheme);
  const dispatch = useDispatch();

  const toggleThemeHandler = () => {
    dispatch(toggleTheme());
  };

  const currentTheme = theme === 'dark' ? darkTheme : lightTheme;

  return useMemo(() => ({
    ...currentTheme,
    toggleTheme: toggleThemeHandler,
  }), [currentTheme, toggleThemeHandler]);
}
