import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Input, message } from 'antd';
import { usePosts } from '../../hooks/posts/usePosts';

export const PostList = () => {
  const { posts, queryPosts, editPost, removePost } = usePosts();
  const [editingPost, setEditingPost] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { TextArea } = Input;

  const fetchData = async () => {
    try {
      await queryPosts(0);
    } catch(e) {
      console.log('PostList, error = ', e);
      message.error(`${e.code}, ${e.message}`);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = (post) => {
    setEditingPost(post);
    setIsModalVisible(true);
  };

  const handleDelete = (postId) => {
    removePost(postId);
  };

  const handleOk = (values) => {
    editPost({ ...editingPost, ...values });
    setIsModalVisible(false);
    setEditingPost(null);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingPost(null);
  };

  const renderedPosts = posts.map(post => (
      <article className="px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md" key={post.id}>
        <h3>{post.title}</h3>
        <p className="post-content">{post.content.substring(0, 100)}</p>
        <Button type="link" onClick={() => handleEdit(post)}>编辑</Button>
        <Button type="link" onClick={() => handleDelete(post.id)}>删除</Button>
      </article>
  ));

  return (
      <section className="posts-list">
        <h2>文章列表</h2>
        {renderedPosts}
        <Modal
            title="编辑文章"
            visible={isModalVisible}
            onCancel={handleCancel}
            footer={null}
        >
          <Form
              initialValues={editingPost}
              onFinish={handleOk}
          >
            <Form.Item
                label="文章标题"
                name="title"
                rules={[{ required: true, message: '请输入文章标题' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
                label="内容"
                name="content"
                rules={[{ required: true, message: '请输入文章内容' }]}
            >
              <TextArea />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">保存</Button>
            </Form.Item>
          </Form>
        </Modal>
      </section>
  );
};
