import { createSlice } from '@reduxjs/toolkit'

const postsSlice = createSlice({
  name: 'posts',
  initialState: {
    items: []
  },
  reducers: {
    postAdded(state, action) {
      state.items.push(action.payload)
    },
    listPosts(state, action) {
      state.items = action.payload
    },
    updatePost: (state, action) => {
      const idx = state.items.findIndex(post => post.id === action.payload.id)
      if (idx !== -1) {
        state.items[idx] = action.payload
      }
    },
    deletePost: (state, action) => {
      state.items = state.items.filter(post => post.id !== action.payload.id)
    }
  }
})

export const { postAdded, listPosts, updatePost, deletePost } = postsSlice.actions

export default postsSlice.reducer
