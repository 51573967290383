import {useDesignToken} from "../theme/hooks/useDesignToken";
import {HEADER_HEIGHT} from './config';
import AccountDropdown from '../components/AccountDropdown';
import {SunOutlined, MoonOutlined} from '@ant-design/icons';
import {Breadcrumb} from "antd";
import React, {useState} from "react";
import useSecurity from "../hooks/security/useSecurity";
import {useNavigate} from 'react-router-dom';

const Header = () => {
    const navigate = useNavigate();
    const {colorBgElevated, toggleTheme} = useDesignToken();
    const {menus, getUserInfo} = useSecurity();
    const [hoveredItem, setHoveredItem] = useState(null);

    const handleClick = (path) => {
        navigate(path);
    };

    const headerStyle = {
        position: 'fixed',
        borderBottom: '',
        backgroundColor: colorBgElevated,
        right: '0px',
        left: 'auto',
        width: '100vw',
    };

    const breadcrumbItemStyle = {
        cursor: 'pointer',
        color: '#1890ff',
        transition: 'color 0.3s ease, background-color 0.3s ease',
        backgroundColor: hoveredItem === null ? 'transparent' : (hoveredItem ? '#e6f7ff' : 'transparent'),
        padding: '0.5rem',
        borderRadius: '0.25rem',
    };

    return (
        <>
            <header className={`z-20 w-full`} style={headerStyle}>
                <div
                    className="flex items-center justify-between flex-grow px-4 text-gray xl:px-6 2xl:px-10"
                    style={{
                        height: HEADER_HEIGHT,
                    }}
                >
                    <div className="flex items-baseline">
                    </div>
                    <div className="flex">
                        <Breadcrumb>
                            {menus.map((menu) => (
                                <Breadcrumb.Item
                                    key={menu.key}
                                    onClick={() => handleClick(menu.key)}
                                    style={breadcrumbItemStyle}
                                    onMouseEnter={() => setHoveredItem(menu.key)}
                                    onMouseLeave={() => setHoveredItem(null)}
                                >
                                    {menu.label}
                                </Breadcrumb.Item>
                            ))}
                        </Breadcrumb>

                    </div>
                    <div className='flex'>
                        <button onClick={toggleTheme}>
                            {colorBgElevated === '#FFF' ? <SunOutlined/> : <MoonOutlined/>}
                        </button>
                        &nbsp;&nbsp;
                        <AccountDropdown/>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header;
